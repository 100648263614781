/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* angular-cli file: src/styles.css */
// @import "../../node_modules/angular-calendar/css/angular-calendar.css";
@import '../../node_modules/angular-calendar/scss/angular-calendar.scss';

$radius: 8px;

.cal-month-view {
  border-radius: $radius;

  .cal-cell-row .cal-cell:hover,
  .cal-cell-row:hover,
  .cal-cell.cal-has-events.cal-open,
  .cal-day-cell.cal-open {
    background: transparent;
  }

  .cal-day-cell {
    &.cal-in-month {
      cursor: default !important;
    }

    > div {
      border: solid 1px transparent;
    }

    .cal-day-number {
      font-size: 1rem !important;
    }

    &.cal-out-month {
      background-color: transparent;
      border: solid 1 transparent;
    }

    &.cal-today {
      background-color: rgba(252, 121, 7, 0.05);
    }

    &.cal-today > div,
    > .selected {
      border: 1px solid #f16531;
    }
  }

  .cal-cell-top {
    min-height: 48px !important;
    flex: 1;
  }

  .cal-header {
    .cal-cell {
      font-size: 16px !important;
      text-transform: uppercase !important;
      color: #6b6b6b;
      padding: 16px !important;
      text-align: right;

      &:first-of-type {
        border-radius: $radius 0 0 0;
      }
      &:last-of-type {
        border-radius: 0 $radius 0 0;
      }
    }
  }

  .cal-day-number {
    margin: 18px 18px 0;
  }

  .cal-day-cell {
    overflow: hidden;

    &.cal-out-month .cal-day-number {
      opacity: 1;
      color: #c1c1c1;
    }
  }
  .cal-day-cell:hover.cal-weekend,
  .cal-days .cal-weekend {
    background: #f9f9f9;
  }

  .cal-out-month {
    pointer-events: none;
  }
}

mwl-calendar-open-day-events {
  display: none !important;
}

.cdk-overlay-pane {
  margin: 4px 16px;
}

.event-details {
  padding: 16px;
  pointer-events: none;

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  .head {
    display: flex;
    align-items: center;

    h3 {
      flex: 1;
      font-size: 16px;
      font-weight: 600;
    }

    button {
      pointer-events: all;
    }
  }

  ul {
    > li {
      margin-top: 8px;
      display: flex;
      line-height: 24px;

      > i,
      > .mat-icon {
        margin-right: 16px;
        width: 24px;
        height: 24px;
        display: block;
        min-width: 24px;
      }
    }
  }
}
